import React from 'react';
// import styled from 'styled-components';
import {
	AreaChart,
	Area,
	XAxis,
	YAxis,
	// CartesianGrid,
	// BarChart,
	// Bar,
	// Tooltip,
	ResponsiveContainer,
} from 'recharts';
import { PriceData } from '../types/api';
// import Moment from 'moment';
// import FormateNumber from '../functions/formatNumber';

// import { StoreState } from '../redux/reducers/index';
// import { useSelector } from 'react-redux';

interface IProps {
	priceData: PriceData[] | undefined;
	lineColor: string;
	change: number;
}

const DummyData = [
	{
		time: '11:00am',
		price: 1,
	},
	{
		time: '1:00pm',
		price: 1,
	},
];

const PricingChart = (props: IProps) => {
	const { priceData, change } = props;
	const gradient = React.useMemo(() => {
		if (change > 0) {
			return (
				<defs>
					<linearGradient id="linearColorUp" x1="0" y1="0" x2="0" y2="1">
						<stop offset="5%" stopColor="#16c784" stopOpacity={0.8} />
						<stop offset="80%" stopColor="#16c784" stopOpacity={0} />
					</linearGradient>
				</defs>
			);
		} else {
			return (
				<defs>
					<linearGradient id="linearColorDown" x1="0" y1="0" x2="0" y2="1">
						<stop offset="5%" stopColor="#ea3943" stopOpacity={0.8} />
						<stop offset="80%" stopColor="#ea3943" stopOpacity={0} />
					</linearGradient>
				</defs>
			);
		}
	}, [change]);

	const graph = React.useMemo(() => {
		if (change > 0) {
			return (
				<Area
					type="monotone"
					dataKey="price"
					strokeWidth={2}
					stroke="#16c784"
					fill={`url(#linearColorUp)`}
				/>
			);
		} else {
			return (
				<Area
					type="monotone"
					dataKey="price"
					strokeWidth={2}
					stroke="#ea3943"
					fill={`url(#linearColorDown)`}
				/>
			);
		}
	}, [change]);

	return (
		<>
			<ResponsiveContainer width="100%" height="100%">
				<AreaChart
					data={
						priceData ? (priceData.length <= 1 ? DummyData : priceData) : DummyData
					}
					margin={{
						top: 0,
						right: 0,
						left: 0,
						bottom: 0,
					}}
				>
					{gradient}
					{/* <CartesianGrid strokeDasharray="3 3" /> */}
					{/* <XAxis dataKey="name" /> */}
					<YAxis hide={true} domain={['auto', 'auto']} />
					<XAxis hide={true} dataKey="time" />
					{/* <Tooltip formatter={(value) => `$${FormateNumber(value)}`} /> */}
					{graph}
				</AreaChart>
			</ResponsiveContainer>
			{/* <BarChart width={150} height={40} data={priceData}>
        <Bar dataKey="uv" fill="#8884d8" />
      </BarChart> */}
		</>
	);
};

export default PricingChart;
